<template>
  <div>
    <form-wizard
      color="#3e74c7"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Létrehozás"
      next-button-text="Tovább"
      next-button-icon="fa fa-arrow-right"
      back-button-text="Vissza"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >

      <!-- account datails tab -->
      <tab-content title="Projekt adatok">
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Projekt adatai
            </h5>
            <small class="text-muted">
              Kérlek add meg az információkat
            </small>
          </b-col>
          <b-col md="6" v-show="unique">
            <b-form-group
              label="Munka azonosító"
              label-for="work_number"
            >
              <b-form-select
                id="work_number"
                v-model="work_number"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="work_numberOptions"
              />
              <small><span class="text-primary" style="cursor:pointer" @click="unique = !unique">Egyedi munka azonosító megadása</span></small>
            </b-form-group>
          </b-col>
          <b-col md="6" v-show="!unique">
            <b-form-group
              label="Munka azonosító"
              label-for="work_number"
            >
              <b-form-input
                id="work_number-2"
                v-model="work_number"
              />
              <small><span class="text-primary" style="cursor:pointer" @click="unique = !unique">Már használatban lévő munka azonosító megadása</span></small>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Rövid megnevezés"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Rövid megnevezés"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Dátum"
              label-for="start_date"
            >
              <b-form-input
                id="start_date"
                v-model="start_date"
                type="date" max="9999-12-31"
                placeholder="Dátum"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Megrendelő"
              label-for="customer"
            >
              <b-form-input
                id="customer"
                v-model="customer"
                placeholder="Megrendelő"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Építési engedély szám"
              label-for="building_permit"
            >
              <b-form-input
                id="building_permit"
                v-model="building_permit"
                placeholder="Építési engedély szám"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Melyik céghez tartozik"
              label-for="monolit"
            >
              <b-form-select
                id="monolit"
                v-model="monolit"
                :options="monolitOptions"
                placeholder="Melyik céghez tartozik"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Hosszú megnevezés"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="description"
                placeholder="Hosszú megnevezés"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Vállalt összeg"
              label-for="price"
            >
              <b-form-input
                id="price"
                type="number"
                v-model="price"
                placeholder="Vállalt összeg"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="FAD"
              label-for="fad"
            >
              <b-form-select
                id="fad"
                v-model="fad"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="fadOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Építés vezető"
              label-for="foreman"
            >
              <b-form-select
                id="foreman"
                v-model="foreman"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="employeeOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Előkészítő"
              label-for="preparatory"
            >
              <b-form-select
                id="preparatory"
                v-model="preparatory"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="employeeOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Vállalt befejezés napja"
              label-for="end_date"
            >
              <b-form-input
                id="end_date"
                v-model="end_date"
                type="date" max="9999-12-31"
                placeholder="Vállalt befejezés napja"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Elszámolás"
              label-for="payment_type"
            >
              <b-form-select
                id="payment_type"
                v-model="payment"
                :options="paymentOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Biztosító"
              label-for="insurance"
            >
              <b-form-input
                id="insurance"
                v-model="insurance"
                placeholder="Biztosító"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Biztosítás lejárta"
              label-for="insurance_end_date"
            >
              <b-form-input
                id="insurance_end_date"
                v-model="insurance_end_date"
                placeholder="Biztosítás lejárta"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" class="mt-1">
            <input
              type="checkbox"
              v-model="bank_enabled"
            > Bank garancia ellenőrzése
          </b-col>
        </b-row>
      </tab-content>
      <tab-content title="Mérföldkövek">
        <b-row class="mb-2" v-if="payment == 'egyedi'">
          <b class="col-lg-12 mt-2 mb-1">Mérföldkövek</b>
          <b-col md="6">
            <b-form-group
              label="Mérföldkő neve"
              label-for="milestone_name"
            >
              <b-form-input
                id="milestone_name"
                v-model="milestone_name"
                type="text"
                placeholder="Mérföldkő neve"
              />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group
              label="Teljesítés dátuma"
              label-for="milestone_date"
            >
              <b-form-input
                id="milestone_date"
                v-model="milestone_date"
                type="date" max="9999-12-31"
                placeholder="Teljesítés dátuma"
              />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <br class="d-none d-md-block d-lg-block"/>
            <button class="btn btn-primary w-100" @click="addToMilestoneList">
              <feather-icon
                icon="PlusCircleIcon"
                size="16"
              />
            </button>
          </b-col>
        </b-row>
        <b-row class="mb-2" v-if="payment === 'havi'">
          <b class="col-lg-12 mt-2 mb-1">Mérföldkövek</b>
          <b-col md="6">
            <b-form-group
              label="Mérföldkövek száma"
              label-for="milestone_number"
            >
              <b-form-input
                id="milestone_number"
                @change="changeMilestoneNumber"
                v-model="milestone_number"
                type="number"
                placeholder="Mérföldkövek száma"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-row v-for="(milistone, index) in milestones" :key="index">
              <b-col md="12" v-if="index == 0">
                <hr/>
              </b-col>
              <b-col md="5">
                <b>{{ milistone.name }}</b>
              </b-col>
              <b-col md="5">
                {{ milistone.date }}
              </b-col>
              <b-col md="1">
                <button class="btn btn-primary w-100" @click="editFromMilestoneList(index)">
                  <feather-icon
                    icon="Edit2Icon"
                    size="16"
                  />
                </button>
              </b-col>
              <b-col md="1">
                <button class="btn btn-primary w-100" @click="removeFromMilestoneList(index)">
                  <feather-icon
                    icon="MinusCircleIcon"
                    size="16"
                  />
                </button>
              </b-col>
              <b-col md="12">
                <hr/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content title="Alvállalkozók">
        <b-row>
          <b-col md="12">
            <b-row class="mb-2">
              <b class="col-lg-12 mt-2 mb-1">Alvállalkozók</b>
              <b-col md="3">
                <b-form-group
                  label="Alvállakozó"
                  label-for="subcontractor"
                >
                  <b-form-select
                    id="subcontractor"
                    v-model="selectedSubcontractor"
                    :options="subcontractorOptions"
                    :value-field="'id'"
                    :text-field="'company_name'"
                    placeholder="Mérföldkő neve"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Elvégzendő munka"
                  label-for="selectedJob"
                >
                  <b-form-select
                    id="selectedJob"
                    v-model="selectedJob"
                    :options="jobOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Kezdés dátuma"
                  label-for="subcontractor_start"
                >
                  <b-form-input
                    id="subcontractor_start"
                    v-model="subcontractor_start"
                    type="date" max="9999-12-31"
                    placeholder="Kezdés dátuma"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Befejezés dátuma"
                  label-for="subcontractor_end"
                >
                  <b-form-input
                    id="subcontractor_end"
                    v-model="subcontractor_end"
                    type="date" max="9999-12-31"
                    placeholder="Befejezés dátuma"
                  />
                </b-form-group>
              </b-col>
              <b-col md="1">
                <br class="d-none d-md-block d-lg-block"/>
                <button class="btn btn-primary w-100" @click="addToSubcontractorList">
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="16"
                  />
                </button>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-row v-for="(subcontractor, index) in subcontractors" :key="index">
                  <b-col md="12" v-if="index == 0">
                    <hr/>
                  </b-col>
                  <b-col md="3">
                    <b>{{ subcontractor.name }}</b>
                  </b-col>
                  <b-col md="2">
                    <b>{{ subcontractor.job }}</b>
                  </b-col>
                  <b-col md="2">
                    {{ subcontractor.date }}
                  </b-col>
                  <b-col md="3">
                    {{ subcontractor.date_end }}
                  </b-col>
                  <b-col md="1">
                    <button class="btn btn-primary w-100" @click="editFromSubcontrator(index)">
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                      />
                    </button>
                  </b-col>
                  <b-col md="1">
                    <button class="btn btn-primary w-100" @click="removeFromSubcontractorsList(index)">
                      <feather-icon
                        icon="MinusCircleIcon"
                        size="16"
                      />
                    </button>
                  </b-col>
                  <b-col md="12">
                    <hr/>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
    <b-modal id="edit_milestone" title="Mérföldkő szerkesztése" hide-footer>
      <b-col md="12">
        <b-form-group
          label="Mérföldkő neve"
          label-for="milestone_name"
        >
          <b-form-input
            id="milestone_name"
            v-model="edit_milestone_name"
            type="text"
            placeholder="Mérföldkő neve"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Teljesítés dátuma"
          label-for="milestone_date"
        >
          <b-form-input
            id="milestone_date"
            v-model="edit_milestone_date"
            type="date" max="9999-12-31"
            placeholder="Teljesítés dátuma"
          />
        </b-form-group>
      </b-col>
      <b-col md="12" class="text-right">
        <button class="btn btn-primary" @click="updateMilestone()">Frissítés</button>
      </b-col>
    </b-modal>
    <b-modal id="edit_subcontractor" title="Alvállalkozó szerkesztése" hide-footer>
      <b-col md="12">
        <b-form-group
          label="Alvállalkozó"
          label-for="edit_subcontractor_name"
        >
          <b-form-select
            id="edit_subcontractor_name"
            v-model="edit_subcontractor_name"
            :options="subcontractorOptions"
            :value-field="'id'"
            :text-field="'company_name'"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Elvégzendő munka"
          label-for="edit_subcontractor_job"
        >
          <b-form-select
            id="edit_subcontractor_job"
            v-model="edit_subcontractor_job"
            :options="jobOptions"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Kezdés dátuma"
          label-for="edit_subcontractor_start"
        >
          <b-form-input
            id="edit_subcontractor_start"
            v-model="edit_subcontractor_start"
            type="date" max="9999-12-31"
            placeholder="Kezdés dátuma"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Befejezés dátuma"
          label-for="edit_subcontractor_end"
        >
          <b-form-input
            id="edit_subcontractor_end"
            v-model="edit_subcontractor_end"
            type="date" max="9999-12-31"
            placeholder="Befejezés dátuma"
          />
        </b-form-group>
      </b-col>
      <b-col md="12" class="text-right">
        <button class="btn btn-primary" @click="updateSubcontractor()">Frissítés</button>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getAuthUsers } from '@/middleware/authLoader'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    VueAutosuggest,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  watch: {
    work_number(val) {
      if (this.unique) {
        this.getDataByWorkNumber(val)
      }
    },
    payment() {
      this.milestones = []
      this.milestone_number = null
    },
  },
  data() {
    return {
      getAuthUsers,
      edit_subcontractor_name: null,
      edit_subcontractor_job: null,
      edit_subcontractor_start: null,
      edit_subcontractor_end: null,
      edit_subcontractor_index: null,
      edit_milestone_date: null,
      edit_milestone_name: null,
      bank_enabled: false,
      edit_milestone_index: null,
      employeeOptions: [],
      milestone_number: null,
      work_number: null,
      name: null,
      start_date: null,
      end_date: null,
      customer: null,
      building_permit: null,
      description: null,
      price: null,
      fad: null,
      subcontractor_start: null,
      subcontractor_end: null,
      unique: true,
      milestones: [],
      subcontractorOptions: [],
      selectedSubcontractor: null,
      selectedJob: null,
      jobOptions: ['betonacél szerelés', 'beton szállítás', 'betonacél szállítás', 'földmunka', 'komplett szerkezetépítés', 'kőműves munkák', 'szerkezetépítés', 'zsalu bontás és takarítás'],
      subcontractor: [],
      subcontractors: [],
      milestone_name: null,
      milestone_date: null,
      monolit: null,
      monolitOptions: [
        {
          text: 'Monolit Épszer Kft.',
          value: 'ME',
        },
        {
          text: 'Monolit Profi Kft.',
          value: 'MP',
        },
      ],
      foreman: null,
      preparatory: null,
      insurance_end_date: null,
      insurance: null,
      work_numberOptions: [],
      payment: 'havi',
      paymentOptions: [
        {
          text: 'Havi elszámolás',
          value: 'havi',
        },
        {
          text: 'Egyedi elszámolás',
          value: 'egyedi',
        },
      ],
      fadOptions: [
        {
          text: '+ 27%',
          value: '+ 27%',
        },
        {
          text: '-% FAD',
          value: '-% FAD',
        },
      ],
    }
  },
  mounted() {
    (async () => {
      this.getAuthUsers('all').then(res => {
        res.forEach(user => {
          this.employeeOptions.push({
            value: user.id,
            text: user.name,
          })
        })
      })
    })()

    axios({
      method: 'get',
      url: 'project/task/work_id_list',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.datasuggest = res.data
    })

    axios({
      method: 'get',
      url: 'project/task/list_work_number/list',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.work_numberOptions = res.data
    })

    axios({
      method: 'get',
      url: 'subcontractor/list',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.subcontractorOptions = res.data
    })

    if (this.$route.params.id) {
      axios({
        method: 'get',
        
        url: `project/offer/show/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.work_number = res.data.work_id
        this.name = res.data.title
        this.customer = res.data.customer
        this.preparatory = res.data.target_id
      })
    }
  },
  methods: {
    editFromSubcontrator(index) {
      this.edit_subcontractor_end = this.subcontractors[index].date_end
      this.edit_subcontractor_start = this.subcontractors[index].date
      this.edit_subcontractor_job = this.subcontractors[index].job
      this.edit_subcontractor_name = this.subcontractors[index].id
      this.edit_subcontractor_index = index
      this.$bvModal.show('edit_subcontractor')
    },
    updateSubcontractor() {
      this.subcontractors[this.edit_subcontractor_index].id = this.edit_subcontractor_name
      this.subcontractors[this.edit_subcontractor_index].name = this.subcontractorOptions.find(subcontractor => subcontractor.id === this.edit_subcontractor_name).company_name
      this.subcontractors[this.edit_subcontractor_index].job = this.edit_subcontractor_job
      this.subcontractors[this.edit_subcontractor_index].date = this.edit_subcontractor_start
      this.subcontractors[this.edit_subcontractor_index].date_end = this.edit_subcontractor_end
      this.$bvModal.hide('edit_subcontractor')
    },
    updateMilestone() {
      this.milestones[this.edit_milestone_index].name = this.edit_milestone_name
      this.milestones[this.edit_milestone_index].date = this.edit_milestone_date
      this.$bvModal.hide('edit_milestone')
      this.sortMilestonesByDate()
    },
    editFromMilestoneList(index) {
      this.edit_milestone_name = this.milestones[index].name
      this.edit_milestone_date = this.milestones[index].date
      this.edit_milestone_index = index
      this.$bvModal.show('edit_milestone')
    },
    removeFromMilestoneList(index) {
      this.milestones.splice(index, 1)
      this.sortMilestonesByDate()
    },
    removeFromSubcontractorsList(index) {
      this.subcontractors.splice(index, 1)
      this.sortSubcontractorsByDate()
    },
    sortMilestonesByDate() {
      this.milestones = this.milestones.sort((a, b) => new Date(a.date) - new Date(b.date))
    },
    sortSubcontractorsByDate() {
      this.subcontractors = this.subcontractors.sort((a, b) => new Date(a.date) - new Date(b.date))
    },
    // eslint-disable-next-line consistent-return
    changeMilestoneNumber() {
      this.milestones = []
      if (this.start_date === null) {
        this.milestone_number = null
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kérlek add meg a kezdő dátumot!',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      }
      // eslint-disable-next-line camelcase
      const start_date = new Date(this.start_date)
      // eslint-disable-next-line camelcase
      const start_date_last_day = new Date(
        start_date.getFullYear(),
        start_date.getMonth() + 1,
        0,
      )
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.milestone_number; i++) {
        const milestone_date = new Date(
          start_date_last_day.getFullYear(),
          start_date_last_day.getMonth() + (i + 1),
          0,
        )
        this.milestones.push({
          name: `${(i + 1)}. Mérföldkő`,
          date: this.formatDate(milestone_date),
          done: false,
        })
        this.sortMilestonesByDate()
      }
    },
    formatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
    addToMilestoneList() {
      if (this.milestone_name === null || this.milestone_date === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező!',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        this.milestones.push({
          name: this.milestone_name,
          date: this.milestone_date,
          done: false,
        })
        this.milestone_name = null
        this.milestone_date = null
        this.sortMilestonesByDate()
      }
    },
    addToSubcontractorList() {
      if (this.subcontractor_start === null || this.selectedSubcontractor === null || this.subcontractor_end === null || this.selectedJob === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező!',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        this.subcontractors.push({
          id: this.selectedSubcontractor,
          name: this.subcontractorOptions.find(subcontractor => subcontractor.id === this.selectedSubcontractor).company_name,
          date: this.subcontractor_start,
          date_end: this.subcontractor_end,
          job: this.selectedJob,
        })
        this.subcontractor_start = null
        this.selectedSubcontractor = null
        this.selectedJob = null
        this.sortSubcontractorsByDate()
      }
    },
    getDataByWorkNumber(val) {
      axios({
        method: 'post',
        url: 'project/task/list_work_number/list/data',
        data: {
          work_number: val,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.customer = res.data.customer
        this.name = res.data.title

        if (val.substring(0, 2) === 'ME') {
          this.monolit = 'ME'
        } else {
          this.monolit = 'MP'
        }
      })
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Kérés elküldve feldolgozásra',
          icon: 'LoaderIcon',
          variant: 'warning',
        },
      })
      axios({
        method: 'post',
        url: 'project/create',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          work_number: this.work_number,
          name: this.name,
          start_date: this.start_date,
          end_date: this.end_date,
          customer: this.customer,
          building_permit: this.building_permit,
          description: this.description,
          price: this.price,
          fad: this.fad,
          foreman: this.foreman,
          preparatory: this.preparatory,
          milestones: this.milestones,
          subcontractor: this.subcontractors,
          insurance_end_date: this.insurance_end_date,
          insurance: this.insurance,
          payment: this.payment,
          bank_enabled: this.bank_enabled,
          monolit: this.monolit,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen létrehozva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'monolit.projects.list' })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
  .flatpickr-input{
    display: none !important;
  }
</style>

<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
  .autosuggest__results-container{
    background: var(--primary);
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .autosuggest__results-item{
    list-style: square;
    margin-top: 3px;
  }
</style>
